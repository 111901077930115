body, p, h1, h2, li, label {
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
}

.App {
  text-align: center;
  width: 100vw;
  height:100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* App Menu */
  .icon-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .icon-menu-panel {
    position: fixed;
    top: 0;
    left: -190px; /* Initially off-screen */
    width: 150px;
    height: 100vh;
    background-color: white;
    transition: transform 0.3s ease;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .icon-menu-panel.open {
    transform: translateX(190px); /* Slide in from left */
  }

  .icon-menu-button {
    background: none;
    border: none;
    padding: 10px;
    font-size: 16px;
  }

.Geeklist-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 6px 16px;
}

.Geeklist-header * {
  margin: 0px;
  align-self: center;
}

.Geeklist-header-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.Geeklist-header-bar button:first-of-type {
  margin-left: auto;
}

h1 {
  font-size: 1.5em;
}

p {
  font-size: .6em;
}

.bar {
  font-size: 10px;
}

.about-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.about-section button {
  align-self: center;
}

.about-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;
}

.about-details caption {
  font-size: .6em;
  text-align: right;
  padding-right: 16px;
  padding-top: 16px;
}

button {
  background: #5E5DF0;
  border-radius: 999px;
  /* box-shadow: #5E5DF0 0 10px 20px -10px; */
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: .5em;
  line-height: 18px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

button:disabled {
  opacity: 0.5;
}

/* @media screen and (max-width: 600px) {
  button {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
  }
} 
*/

label {
  font-size: .6em;
}

label:after{
  content: ':';
}

input {
  padding: 1px 12px;
  border-radius: 999px;
  line-height: 18px;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.inputGroup * {
  align-self: center;
  /* font-size: .6em; */
}

.about-section li {
  text-align: left;
  font-size: .6em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

/* Modal Content */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Drop shadow effect */
  max-width: 80%;
  max-height: 80%;
  overflow: auto; /* Enable scrolling if content overflows */
  z-index: 100;
  min-width:300px;
  width: 80%;
  display: flex;
  flex-direction: column;
}

/* Close Button */
.close-button {
  align-self: flex-end;
  background: none;
  border: none;
  cursor: pointer;
  /* font-size: 16px; */
}

.tableControls{
  padding: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  width: calc(100% - 24px);
}

.tableControls > *{
  align-self: center;
}

.tableInfo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: #f8f8f8;
  border: 1px solid #babfc7;
  border-bottom: 0px;
  font-size: 13px;
  padding: 6px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
  align-items: center;
}

tbody tr:nth-child(even) {
  background-color: #e8e8e8;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

td {
  padding: 10px;
}

/* loading animation */
.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: spin 2s linear infinite;
}

/* full page loading indicator classes */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 100;
}

.loading-overlay {
  position: absolute;
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px; 
}

.loading-inline-container{
  margin-left: auto;
}

.loading-inline-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.loading-inline-content * {
  align-self: center;
}

.progress-bar-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.progress-bar-container * {
  align-self: center;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  min-width: 100px;
}

.progress {
  height: 100%;
  width: 0%;
  background-color: #3498db;
  border-radius: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

